// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-application-form-for-veterinarians-tsx": () => import("./../../../src/pages/application-form-for-veterinarians.tsx" /* webpackChunkName: "component---src-pages-application-form-for-veterinarians-tsx" */),
  "component---src-pages-download-pav-for-vets-app-tsx": () => import("./../../../src/pages/download-pav-for-vets-app.tsx" /* webpackChunkName: "component---src-pages-download-pav-for-vets-app-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-registration-information-tsx": () => import("./../../../src/pages/registration-information.tsx" /* webpackChunkName: "component---src-pages-registration-information-tsx" */),
  "component---src-pages-terms-and-conditions-for-veterinarians-tsx": () => import("./../../../src/pages/terms-and-conditions-for-veterinarians.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-for-veterinarians-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-vet-profile-tsx": () => import("./../../../src/pages/vet-profile.tsx" /* webpackChunkName: "component---src-pages-vet-profile-tsx" */),
  "component---src-pages-veterinarians-id-tsx": () => import("./../../../src/pages/veterinarians/id.tsx" /* webpackChunkName: "component---src-pages-veterinarians-id-tsx" */),
  "component---src-pages-veterinarians-index-tsx": () => import("./../../../src/pages/veterinarians/index.tsx" /* webpackChunkName: "component---src-pages-veterinarians-index-tsx" */),
  "component---src-pages-veterinarians-legacy-tsx": () => import("./../../../src/pages/veterinarians-legacy.tsx" /* webpackChunkName: "component---src-pages-veterinarians-legacy-tsx" */)
}

